import React, { useState, useEffect } from 'react';
import './App.css';
import * as XLSX from 'xlsx';

const Modal = ({ isOpen, onClose, onSubmit }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    onSubmit(password);
    setPassword('');
  };

  return (
    isOpen && (
      <div
        style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
        <div style={{ backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', maxWidth: '400px', width: '80%', padding: '20px', textAlign: 'center' }}>
          <h2 style={{ marginBottom: '20px' }}>Nhập mật khẩu để xóa</h2>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} style={{ marginBottom: '20px', width: 'calc(100% - 40px)', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', boxSizing: 'border-box' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button onClick={handleSubmit} style={{ marginRight: '10px', padding: '10px 20px', borderRadius: '4px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>Xóa</button>
            <button onClick={onClose} style={{ padding: '10px 20px', borderRadius: '4px', backgroundColor: '#f44336', color: '#fff', border: 'none', cursor: 'pointer' }}>Hủy</button>
          </div>
        </div>
      </div>

    )
  );
};

const ITEMS_PER_PAGE = 50;

const App = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [dictionary, setDictionary] = useState([]);
  const [filteredDictionary, setFilteredDictionary] = useState([]);
  const [newWord, setNewWord] = useState({
    vietnamese: '',
    chinese: '',
    phonetic: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [tempDictionary, setTempDictionary] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchIndex, setSearchIndex] = useState(null);
  const [searchDictionary, setSearchDictionary] = useState([]);

  useEffect(() => {
    fetchDictionary();
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(dictionary.length / ITEMS_PER_PAGE));
  }, [dictionary]);

  const paginateDictionary = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return dictionary.slice(startIndex, endIndex);
  };

  const fetchDictionary = () => {
    const url = 'https://back-end-tracuu.vercel.app/dictionary';

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setDictionary(data);
        console.log('Dictionary fetched successfully:', data);
      })
      .catch(error => {
        console.error('Error fetching dictionary:', error);
        console.log('Failed to fetch dictionary.');
      });
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (!value || dictionary.length === 0) {
      setFilteredDictionary([]);
      return;
    }
    const filteredWords = dictionary.filter(word => {
      return (
        (word.vietnamese && word.vietnamese.toLowerCase().includes(value.toLowerCase())) ||
        (word.chinese && word.chinese.toLowerCase().includes(value.toLowerCase()))
      );
    });
    setFilteredDictionary(filteredWords);
  };

  const handleSearch = () => {
    if (!!searchTerm) {
      const value = searchTerm;
      setSearchTerm(value);
      if (!value || dictionary.length === 0) {
        setFilteredDictionary([]);
        return;
      }
      const filteredWords = dictionary.filter(word => {
        return (
          (word.vietnamese && word.vietnamese.toLowerCase().includes(value.toLowerCase())) ||
          (word.chinese && word.chinese.toLowerCase().includes(value.toLowerCase()))
        );
      });
      setFilteredDictionary(filteredWords);
      if (filteredWords.length === 0) {
        setErrorMessage("Không tìm thấy kết quả!!!");
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setFilteredDictionary([]);
    }
  };

  const exportToExcel = (jsonData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleExportButtonClick = () => {
    const ngay = new Date();
    const ngayStr = ngay.getDate().toString().padStart(2, '0');
    const thangStr = (ngay.getMonth() + 1).toString().padStart(2, '0');
    const namStr = ngay.getFullYear();

    exportToExcel(dictionary, `data-tracuu-${ngayStr}-${thangStr}-${namStr}`);
  };

  const handleAddToDictionary = () => {
    if (!!newWord.vietnamese && !!newWord.chinese && !!newWord.phonetic) {
      const url = 'https://back-end-tracuu.vercel.app/dictionary';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newWord)
      })
        .then(response => {
          console.log(response);
          if (response.status === 201) {
            alert('Thêm mới dữ liệu thành công');
            fetchDictionary();
          } else {
            alert('Dữ liệu đã có không thêm lần hai được');
          }
        })
        .then(() => {
          setNewWord({ vietnamese: '', chinese: '', phonetic: '' });
          setError("");
        })
        .catch(error => {
          console.error('Error adding word:', error);
          alert('Thêm từ mới thất bại. Vui lòng thử lại sau.');
        });
    } else {
      setError("Vui lòng nhập đầy đủ thông tin!!!");
    }
  };

  const handleEdit = (index, field, value) => {
    const newTempDictionary = [...tempDictionary];
    newTempDictionary[index][field] = value;
    setTempDictionary(newTempDictionary);
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setTempDictionary([...dictionary]);
  };

  const handleSaveEdit = () => {
    const editedWord = tempDictionary[editingIndex];
    const url = `https://back-end-tracuu.vercel.app/dictionary/${editedWord._id}`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedWord)
    })
      .then(response => {
        if (response.ok) {
          alert('Sửa đổi dữ liệu thành công');
          const updatedDictionary = [...dictionary];
          updatedDictionary[editingIndex] = editedWord;
          setDictionary(updatedDictionary);
          setEditingIndex(null);
          fetchDictionary()
        } else {
          throw new Error('Failed to update dictionary');
        }
      })
      .catch(error => {
        console.error('Error updating dictionary:', error);
      });
  };

  const handleSearchEdit = (index, field, value) => {
    const newTempDictionary = [...searchDictionary];
    newTempDictionary[index][field] = value;
    setSearchDictionary(newTempDictionary);
  };

  const handleSearchEditClick = (index) => {
    setSearchIndex(index);
    setSearchDictionary([...filteredDictionary]);
  };

  const handleSaveSearchEdit = () => {
    const editedWord = searchDictionary[searchIndex];
    const url = `https://back-end-tracuu.vercel.app/dictionary/${editedWord._id}`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedWord)
    })
      .then(response => {
        if (response.ok) {
          alert('Sửa đổi dữ liệu thành công');
          const updatedDictionary = [...filteredDictionary];
          updatedDictionary[searchIndex] = editedWord;
          setFilteredDictionary(updatedDictionary);
          setSearchIndex(null);
          fetchDictionary()
        } else {
          throw new Error('Failed to update dictionary');
        }
      })
      .catch(error => {
        console.error('Error updating dictionary:', error);
      });
  };

  const handleDelete = (index) => {
    setModalOpen(true);
    setDeleteIndex(index);
  };

  const handleConfirmDelete = (password) => {
    if (password === 'admin') {
      const url = `https://back-end-tracuu.vercel.app/dictionary/${deleteIndex}`;
      fetch(url, {
        method: 'DELETE'
      })
        .then(response => {
          if (response.ok) {
            alert('Xóa dữ liệu thành công');
            fetchDictionary();
            setFilteredDictionary([])
            setSearchTerm('');
          } else {
            throw new Error('Failed to delete word');
          }
        })
        .catch(error => {
          console.error('Error deleting word:', error);
          console.log('Failed to delete word.');
        });
    } else {
      alert('Mật khẩu không đúng. Xóa không thành công.');
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setDeleteIndex(null);
  };

  /////
  return (
    <div>
      <div style={{
        textAlign: 'center',
        fontSize: "25px",
        fontWeight: "bold",
        margin: "30px",
        lineHeight: "30px"
      }}>Từ vựng tiếng Trung
        <div>
          chuyên nghành
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Nhập nghĩa tiếng việt hoặc tiếng hán..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            marginBottom: '10px',
            padding: '8px',
            paddingLeft: '16px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            marginRight: '16px',
            height: "36px",
            width: '50%'
          }}
        />
        <button
          onClick={handleSearch}
          style={{
            padding: '8px 16px',
            borderRadius: '4px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            height: "56px",
          }}
        >
          Tìm
        </button>
      </div>

      <div style={{ textAlign: 'center', marginTop: '10px', color: 'red' }}>
        {errorMessage && <p>{errorMessage}</p>}
      </div>

      <div class="flex-container-fex">
        {filteredDictionary.map((word, index) => (
          <div key={index} className="flex-item">
            <div className="input-group">
              <div className="input-field">
                <label>Chữ Hán:</label>
                <input
                  type="text"
                  value={word.chinese}
                  onChange={(event) => handleSearchEdit(index, 'chinese', event.target.value)}
                  readOnly={searchIndex !== index}
                  disabled={searchIndex !== index}
                />
              </div>
              <div className="input-field">
                <label>Phiên âm:</label>
                <input
                  type="text"
                  value={word.phonetic}
                  onChange={(event) => handleSearchEdit(index, 'phonetic', event.target.value)}
                  readOnly={searchIndex !== index}
                  disabled={searchIndex !== index}
                />
              </div>
              <div className="input-field">
                <label>Tiếng việt:</label>
                <input
                  type="text"
                  value={word.vietnamese}
                  onChange={(event) => handleSearchEdit(index, 'vietnamese', event.target.value)}
                  readOnly={searchIndex !== index}
                  disabled={searchIndex !== index}
                />
              </div>
              {searchIndex !== index ? (
                <button className="edit-button" onClick={() => handleSearchEditClick(index)}>Chỉnh sửa</button>
              ) : (
                <>
                  <button className="save-button" onClick={handleSaveSearchEdit}>Lưu</button>
                  <button className="cancel-button" onClick={() => setSearchIndex(null)}>Hủy chỉnh sửa</button>
                </>
              )}
              <button className="delete-button" onClick={() => handleDelete(word._id)}>Xóa</button>
            </div>
          </div>
        ))}
      </div>

      <div class="container">
        <div class="form-container">
          <h2>Thêm từ mới:</h2>
          <div class="input-group">
            <label for="chinese">Chữ Hán:</label>
            <input type="text" id="chinese" value={newWord.chinese} onChange={(event) => setNewWord({ ...newWord, chinese: event.target.value })} />
          </div>
          <div class="input-group">
            <label for="phonetic">Phiên âm:</label>
            <input type="text" id="phonetic" value={newWord.phonetic} onChange={(event) => setNewWord({ ...newWord, phonetic: event.target.value })} />
          </div>
          <div class="input-group">
            <label for="vietnamese">Tiếng việt:</label>
            <input type="text" id="vietnamese" value={newWord.vietnamese} onChange={(event) => setNewWord({ ...newWord, vietnamese: event.target.value })} />
          </div>
          <div class="error-message">
            {error && <p>{error}</p>}
          </div>
          <button class="submit-button"
            onClick={handleAddToDictionary}>Thêm</button>

          <button class="submit-button-edit"
            onClick={handleExportButtonClick}>Xuất file dữ liệu data</button>
        </div>
      </div>

      <div className="flex-container-fex">
        {paginateDictionary().map((word, index) => (
          <div key={index} className="flex-item">
            <div className="input-group">
              <div className="input-field">
                <label>Chữ Hán:</label>
                <input
                  type="text"
                  value={word.chinese}
                  onChange={(event) => handleEdit(index, 'chinese', event.target.value)}
                  readOnly={editingIndex !== index}
                  disabled={editingIndex !== index}
                />
              </div>
              <div className="input-field">
                <label>Phiên âm:</label>
                <input
                  type="text"
                  value={word.phonetic}
                  onChange={(event) => handleEdit(index, 'phonetic', event.target.value)}
                  readOnly={editingIndex !== index}
                  disabled={editingIndex !== index}
                />
              </div>
              <div className="input-field">
                <label>Tiếng việt:</label>
                <input
                  type="text"
                  value={word.vietnamese}
                  onChange={(event) => handleEdit(index, 'vietnamese', event.target.value)}
                  readOnly={editingIndex !== index}
                  disabled={editingIndex !== index}
                />
              </div>
              {editingIndex !== index ? (
                <button className="edit-button" onClick={() => handleEditClick(index)}>Chỉnh sửa</button>
              ) : (
                <>
                  <button className="save-button" onClick={handleSaveEdit}>Lưu</button>
                  <button className="cancel-button" onClick={() => setEditingIndex(null)}>Hủy chỉnh sửa</button>
                </>
              )}
              <button className="delete-button" onClick={() => handleDelete(word._id)}>Xóa</button>
            </div>
          </div>
        ))}

        {totalPages !== 1 &&
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                style={{
                  padding: '8px 12px',
                  margin: '0 5px',
                  borderRadius: '4px',
                  backgroundColor: currentPage === index + 1 ? '#007bff' : '#fff',
                  color: currentPage === index + 1 ? '#fff' : '#007bff',
                  border: '1px solid #007bff',
                  cursor: 'pointer',
                  outline: 'none',
                }}>
                {index + 1 === totalPages ? 'Trang cuối' : index + 1}
              </button>
            ))}
          </div>
        }
      </div>

      <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', textAlign: 'center', backgroundColor: '#fff', padding: '20px 0', borderTop: '1px solid #ccc' }}>
        <div>Donate ủng hộ: BIDV 2143924435</div>
      </div>
      <Modal isOpen={modalOpen} onClose={handleCloseModal} onSubmit={handleConfirmDelete} />
    </div>
  );
};

export default App;

